<template>
    <div class="legislat xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>法律法规</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link
                            to="/legislation/Legislation">法律法规</router-link></p>
                </div>
                <div class="banner-search">
                    <input type="text" v-model="keyword" placeholder="输入关键词，按回车键(Enter)进行搜索"
                        @keydown="SearchInputKeydown">
                    <button class="search-btn el-icon-search" @click="searchLegislation(true)"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="ny-content">
                <ul class="legis-list clearfix">
                    <li class="legis-item" v-for="(legisTitle, i) in legislationList" :index="legisTitle.index"
                        :title="legisTitle.title" @click="tolegisDetail(legisTitle.legislationId)">
                        <div class="legis-img">
                            <img src="../../assets/img/fa_icon.png">
                        </div>
                        <p>{{ legisTitle.title }}</p>
                    </li>
                </ul>
                <div id="page">
                    <el-pagination background layout="prev, pager, next,total" :page-size="21"
                        @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import { Legislation } from "../../components/Legislation/Legislation"
import ftCom from '../../components/footer';
import nhCom from "../../components/navheader";
// import rtBar from "../../components/right";
var pageIndex = 1
var keywordVal = ''
export default {
    name: "Legislation",
    components: {
        ftCom,
        nhCom,
        // rtBar
    },
    data() {
        var legislation = new Legislation(this.TokenClient, this.Services.Statics)
        return {
            legislationList: [],
            total: 0,
            currentPage: 1, //初始页
            keyword: '',
            rloading: [],//loading遮罩
            legislationDomain: legislation
        }
    },
    methods: {
        searchLegislation(isPage = false) {
            var _this = this;
            _this.rloading = this.openLoading()
            if (!isPage) pageIndex = 1
            let params = `${pageIndex}`
            keywordVal = this.keyword

            if (keywordVal != '') {
                params = params + `/${keywordVal}`
            }

            _this.legislationDomain.Legislations(params, function (data) {
                _this.legislationList = data.data.results
                _this.total = data.data.dataTotal
                _this.rloading.close()
            },
                function (error) {
                    console.log("行业遵从获取失败:", error)
                })
            if (!isPage) {
                this.currentPage = 1
            }
        },
        SearchInputKeydown(event) {
            if (event.keyCode == 13) {
                this.searchLegislation(false);
            }
        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum
            pageIndex = pageNum
            this.searchLegislation(true);
            // chrome
            document.body.scrollTop = 125
            // firefox
            document.documentElement.scrollTop = 125
            // safari
            window.pageYOffset = 125
        },
        //跳转到详情
        tolegisDetail(id) {
            this.$router.push({
                path: '/legislation/LegislaDetail',
                //name:'herbalDetail',
                query: {
                    id: id,
                }
            })
        }
    },
    mounted() {
        this.$bus.$emit('isShowChangeSize');
        this.searchLegislation();
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg")center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.banner-search {
    position: absolute;
    top: calc(50% - 30px);
    right: 0;
    padding: 0 30px;
    width: 850px;
    height: 60px;
    border-radius: 60px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7);
}

.banner-search input {
    float: left;
    height: 60px;
    line-height: 60px;
    background: none;
    width: 700px;
    font-size: 16px;
}

.banner-search .search-btn {
    float: right;
    font-size: 28px;
    color: #1fb7a6;
    background: none;
    line-height: 60px;
}

/**/
.content {
    background: #f7f7f7;
    padding: 40px 0;
}

.ny-content {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.legis-item {
    float: left;
    margin: 15px;
    cursor: pointer;
}

.legis-img {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 135px;
    height: 135px;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
}

.legis-item p {
    margin: 10px 0;
    width: 135px;
    height: 60px;
    text-align: center;
    color: #333333;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.legis-item:hover .legis-img {
    border: 1px solid #1fb7a6;
}

.legis-item:hover p {
    color: #1fb7a6;
}

#page {
    text-align: center;
}
</style>
<style>
.legislat .navheader nav .navbar ul li.active>a {
    color: #333333 !important;
}

.legislat .navheader nav .navbar ul li.active>a::before {
    width: 0 !important;
}

.legislat .bar-list .yysc {
    display: none !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.el-pagination {
    font-weight: 500;
}
</style>
